const metaworldSettings = {
	title: "MetaWorld Media",
	description: "A metaverse-integrated news outlet where virtual journalists report on real-world events in an interactive, digital environment. Viewers can attend press conferences, watch interviews, and explore event locations as avatars.",
	logo: "/logo.png",
	logoBig: "/logo512.png",
	headerBg: 'rgb(152,13,15)',
	footerBg: "rgb(152,13,15)",
	headerTextColor: "#fff",
	footerTextColor: "#fff",
	style: 'expose'
};

export default metaworldSettings;
